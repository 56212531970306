import GameEventHandler from './GameEventHandler'
import ScorePresenter from '../score/ScorePresenter'
import GameFinalScorePresenter from '../finalScore/GameFinalScorePresenter'

export default class GameRouter {
  constructor(config) {
    this.view = config.view
    this.rateApp = () => config.rateApp()
    this.loadGame = () => config.loadGame()
    this.rateDelay = config.rateDelay
    this.createScoreAnimator = () =>
      config.createScoreAnimator(
        () => this.view.getQuestionView() && this.goToNextQuestionPage(),
      )
    this.view = config.view

    this.replacePage = (view) => config.replacePage(view)
    this.tracker = config.createTracker()
    this.eventHandler = new GameEventHandler(this.tracker)
  }

  goToLoadingPage() {
    this.resetGame()
    this.trackPageView('loader')
    this.replacePage('loadingScreen')
    this.loadGame()
  }

  didCompleteLoadingGame(game) {
    this.game = game
    this.goToWelcomeAnimationPage()
  }

  goToWelcomeAnimationPage() {
    this.replacePage('welcomeAnimationScreen')
    const view = this.view.getWelcomeAnimationView()
    view.setWelcomeMusic(this.game.welcomeMusic)
    view.setAppearSound(this.game.appearSound)
    view.setSubjectName(this.getGame().getSubject().name)
    view.setUploaderName(this.getGame().getUploader().name)
    view.setSubjectInitial(this.getGame().getSubject().initial)
    view.setUploaderInitial(this.getGame().getUploader().initial)
    view.setSubjectImage(this.getGame().getSubject().image)
    view.setUploaderImage(this.getGame().getUploader().image)
    view.animate(() => this.goToScorePage())
  }

  resetGame() {
    this.scorePresenter = new ScorePresenter({
      getMaxScore: () => this.getMaxScore(),
      animator: this.createScoreAnimator(),
    })
    this.currentQuestionIndex = -1
    this.view.getScoreView().setScore(0)
  }

  goToScorePage() {
    this.trackPageView('score')
    this.replacePage('scoreScreen')
    this.view.getScoreView().setAppearSound(this.game.appearSound)
    this.view.getScoreView().setMaxScore(this.getMaxScore())
    this.view
      .getScoreView()
      .setSubjectInitial(this.getGame().getSubject().initial)
    this.view
      .getScoreView()
      .setUploaderInitial(this.getGame().getUploader().initial)
    this.view.getScoreView().setSubjectImage(this.getGame().getSubject().image)
    this.view
      .getScoreView()
      .setUploaderImage(this.getGame().getUploader().image)
    this.view.getScoreView().setQuestionIndex(this.currentQuestionIndex + 1)
    this.eventHandler.didShowScorePage(
      this.scorePresenter.animator,
      this.currentQuestionIndex,
      this.scorePresenter,
    )
  }

  goToNextQuestionPage() {
    this.trackPageView('question')
    if (!this.hasNextQuestion()) {
      this.goToFinalScorePage()
      return
    }
    this.currentQuestionIndex++
    this.replacePage('questionScreen')
    this.view
      .getQuestionView()
      .setText(this.getGame().getQuestion(this.currentQuestionIndex))
    this.addQuestionRoutes()
  }

  trackPageView(section) {
    this.tracker.trackPageview(`game#${section}`)
  }

  hasNextQuestion() {
    const nextQuestion = this.currentQuestionIndex + 1
    return nextQuestion < this.getMaxScore()
  }

  getCurrentURL() {
    return this.getGame().getVideoURL(this.currentQuestionIndex)
  }

  addQuestionRoutes() {
    this.eventHandler.listenForQuestionEvents(this, this.view.getQuestionView())
  }

  goToAnswerPage() {
    this.trackPageView('answer')
    this.replacePage('answerScreen')
    this.view.getAnswerView().play(this.getCurrentURL())
    this.eventHandler.listenForAnswerEvents(this, this.view.getAnswerView())
  }

  goToAnswerResultPage() {
    this.trackPageView('answer_result')
    this.replacePage('answerResultScreen')
    const view = this.view.getAnswerResultView()
    view.setCorrectSound(this.game.correctSound)
    view.setIncorrectSound(this.game.incorrectSound)
    this.eventHandler.listenForAnswerResultEvents(
      this,
      view,
      this.scorePresenter,
    )
  }

  goToFinalScorePage() {
    this.trackPageView('final_score')
    this.replacePage('finalScoreScreen')
    this.eventHandler.listenForFinalScoreEvents(
      this,
      this.view.getFinalScoreView(),
    )
    this.view.getFinalScoreView().setWelcomeMusic(this.game.welcomeMusic)
    new GameFinalScorePresenter(this.view.getFinalScoreView()).setScore(
      this.scorePresenter.score,
      this.getMaxScore(),
    )
    this.view
      .getFinalScoreView()
      .setSubjectInitial(this.getGame().getSubject().initial)
    this.view
      .getFinalScoreView()
      .setSubjectName(this.getGame().getSubject().name)
    this.view
      .getFinalScoreView()
      .setSubjectImage(this.getGame().getSubject().image)
    this.rateDelay(() => this.rateApp())
  }

  getGame() {
    return this.game
  }

  getMaxScore() {
    return this.getGame().getMaxScore()
  }
}
