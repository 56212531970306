export default class VideoDownloaderWatcher {
  constructor(subject, callbacks) {
    this.subject = subject
    this.onComplete = callbacks.complete ? callbacks.complete : () => {}
    this.onProgress = callbacks.progress ? callbacks.progress : () => {}
    this.onError = callbacks.error ? callbacks.error : () => {}
    this.isPolling = false
  }

  startWatching() {
    this.isPolling = true
    this.observe()
  }

  stopWatching() {
    this.isPolling = false
  }

  isWatching() {
    return this.isPolling
  }

  pollIfNeeded(ms) {
    if (this.isPolling) {
      this.poll(ms)
    }
  }

  poll(ms) {
    setTimeout(() => {
      this.observe()
    }, ms)
  }

  getProgress() {
    return this.subject.progress || {}
  }

  observe() {
    const error = this.getError()
    if (error != null) {
      this.onError(error)
      return
    }
    if (this.isComplete()) {
      this.onComplete(this.subject.response)
      return
    }
    this.onProgress(this.getProgress())
    this.pollIfNeeded(33)
  }

  getError() {
    if (this.subject.error == null) {
      return null
    }
    return this.subjectContainsError()
  }

  subjectContainsError() {
    for (const key in this.subject.error) {
      if (this.subject.error[key] != null) {
        return this.subject.error[key]
      }
    }
    return null
  }

  isComplete() {
    if (this.subject.response == null) {
      return false
    }
    return this.subjectAllComplete()
  }

  subjectAllComplete() {
    for (const key in this.subject.response) {
      if (this.subject.response[key] == null) {
        return false
      }
    }
    return Object.keys(this.subject.response).length > 0
  }
}
