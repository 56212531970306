import React from "react"

export default function IncompletePoint() {
  return (
    <svg width="100%" height="100%" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
      <g id="Redesign-NEW-colours" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Progress-Copy-3" transform="translate(-59.000000, -224.000000)">
          <g id="Group-Copy" transform="translate(59.000000, 193.000000)">
            <g id="completePoint-Copy" transform="translate(1.000000, 32.000000)">
              <circle id="Oval-Copy" stroke="#AAADBF" strokeWidth="0.665" fill="#FFFFFF" cx="9" cy="9" r="9"></circle>
              <circle id="Oval" fill="#AAADBF" cx="9" cy="9" r="6"></circle>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
