export default class Game {
  constructor(items, subject, uploader, sounds) {
    this.items = items
    this.subject = subject
    this.uploader = uploader
    this.sounds = sounds
  }

  get welcomeMusic() {
    return this.sounds && this.sounds.welcome_music
  }

  get appearSound() {
    return this.sounds && this.sounds.appear
  }

  get correctSound() {
    return this.sounds && this.sounds.correct
  }

  get incorrectSound() {
    return this.sounds && this.sounds.incorrect
  }

  getMaxScore() {
    return this.items.length
  }

  getQuestion(index) {
    return this.items[index].question
  }

  getVideoURL(index) {
    return this.items[index].videoURL
  }

  getSubject() {
    return this.subject
  }

  getUploader() {
    return this.uploader
  }

  static fromJSON(json) {
    if (this.isValidJSON(json)) {
      const sounds = json.sounds || {} // Edge case where app has downloaded game before updating the app supporting sounds.
      const subject = json.subject || this.getPlayer(json.subjectName) // Edge case where app has downloaded game before updating app to support player images
      const uploader = json.uploader || this.getPlayer(json.uploaderName) // Edge case where app has downloaded game before updating app to support player images
      return new Game(json.items, subject, uploader, sounds)
    }
  }

  static getPlayer(name) {
    return {name: name, initial: this.getInitial(name), image: null}
  }

  static getInitial(name) {
    return name[0].toUpperCase()
  }

  static isValidJSON(json) {
    return (
      json != null &&
      json.items != null &&
      (json.subjectName || json.subject) &&
      (json.uploaderName || json.uploader)
    )
  }

  toJSON() {
    return this
  }
}
