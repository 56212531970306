export default function AuthorizeRequest(data, token) {
  addData(data)
  addHeaders(data)
  insertToken(data, token)
  return data
}

function addData(data) {
  if (data.data == null) {
    data.data = {}
  }
}

function addHeaders(data) {
  if (data.data.headers == null) {
    data.data.headers = {}
  }
}

function insertToken(data, token) {
  data.data.headers['X-Token'] = token
}
