export default class ScoreAnimator {
  constructor(view, finish) {
    this.view = view
    this.finish = finish
  }

  animateIn() {
    this.view.setState({
      subjectClassName: "score_marker--animateIn",
      onSubjectAnimationEnd: this.removeSubjectAnimation,
      uploaderClassName: "score_marker--uploaderAnimateIn",
      onUploaderAnimationEnd: () => {
        this.finishAfterDelay(1500)
        this.removeUploaderAnimation()
      }
    })

  }

  animateShake() {
    this.view.setState({
      subjectClassName: "score_marker--animateShake",
      onSubjectAnimationEnd: () => {
        this.finishAfterDelay(1500)
        this.removeSubjectAnimation()
      },
    })
  }

  animateMove(index) {
    this.view.setState({
      subjectClassName: "score_marker--animateIncrementScore",
      onSubjectAnimationEnd: () => {
        this.finishAfterDelay(1500)
        setTimeout(() => { // Prevents a flicker on iPhone by replace animation offscreen
          this.removeSubjectAnimation()
          this.view.setScore(index)
        }, 3000)
      },
    })
  }

  animateMaxScore() {
    this.view.setState({
      subjectClassName: "score_marker--animateFinalScore",
      onSubjectAnimationEnd: () => {
        this.view.setState({
          subjectClassName: "score_marker--animateFinalScorePulse",
          onSubjectAnimationEnd: () => { },
        })
      },
      uploaderClassName: "score_marker--animateFinalScoreUploader",
      onUploaderAnimationEnd: () => { },
    })
    this.finishAfterDelay(3500)
  }

  removeSubjectAnimation = () => this.view.setState({ subjectClassName: "" })
  removeUploaderAnimation = () => this.view.setState({ uploaderClassName: "" })

  finishAfterDelay = (delay) => {
    setTimeout(() => {
      this.finish()
    }, delay)
  }
}
