import React from "react"
import './GameAnswerResultStyles.css'
import ThumbsUp from '../svg/ThumbsUp'
import ThumbsDown from '../svg/ThumbsDown'
import ReplayVideo from '../svg/ReplayVideo'
import TouchableButton from "../../ui/TouchableButton";
import Sound from "react-sound"

export default class GameAnswerResultScreen extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      repeat: () => { },
      onCorrect: () => { },
      onIncorrect: () => { },
      correctSound: null,
      incorrectSound: null,
      selectedAnswer: null,
    }
  }

  componentWillDisappear() { }
  componentDidAppear() { }

  onCorrectAnswerButtonPressed(fn) {
    this.setState({ onCorrect: fn })
  }

  onIncorrectAnswerButtonPressed(fn) {
    this.setState({ onIncorrect: fn })
  }

  onRepeatAnswerButtonPressed(fn) {
    this.setState({ repeat: fn })
  }

  setCorrectSound(sound) {
    this.setState({ correctSound: sound })
  }

  setIncorrectSound(sound) {
    this.setState({ incorrectSound: sound })
  }

  renderSound() {
    if (this.state.selectedAnswer === "correct") {
      let onFinish = () => {
        this.setState({ selectedAnswer: null })
        this.state.onCorrect()
      }
      return <Sound
        className="answerResultSound"
        url={this.state.correctSound}
        playStatus={Sound.status.PLAYING}
        onFinishedPlaying={onFinish}
        onError={onFinish}
      />
    } else if (this.state.selectedAnswer === "incorrect") {
      let onFinish = () => {
        this.setState({ selectedAnswer: null })
        this.state.onIncorrect()
      }
      return <Sound
        className="answerResultSound"
        url={this.state.incorrectSound}
        playStatus={Sound.status.PLAYING}
        onFinishedPlaying={onFinish}
        onError={onFinish}
      />
    }
  }

  onClickIncorrect = () => {
    if (this.state.selectedAnswer === null) {
      return () => this.setState({ selectedAnswer: "incorrect" })
    }
  }

  onClickCorrect = () => {
    if (this.state.selectedAnswer === null) {
      return () => this.setState({ selectedAnswer: "correct" })
    }
  }

  render() {

    return (
      <div {...this.props} className="GameAnswerResultScreen__background">
        {this.renderSound()}
        <div className="GameAnswerResultScreen__mainIconContainer">
          <TouchableButton className="mainIconContainer__purpleBox" onClick={this.onClickIncorrect()}>
            <div className="mainIconContainer__icon">
              <ThumbsDown />
            </div>
          </TouchableButton>
          <TouchableButton className="mainIconContainer__whiteBox" onClick={this.onClickCorrect()}>
            <div className="mainIconContainer__icon">
              <ThumbsUp />
            </div>
          </TouchableButton>
        </div>
        <TouchableButton className="replayVideoButton" onClick={this.state.repeat}>
          <ReplayVideo />
        </TouchableButton>
      </div>
    )
  }
}