import React from "react"
import "./PlayerHexagonStyles.css"

export default function PlayerHexagon({ image, color, initial }) {
  return (
    <div className="player_hexagon">
      {image ? <svg width="100%" height="100%" viewBox="0 0 175 200" x="0" y="0">
        <defs>
          <clipPath id="clip-path">
            <polyline id="hexagon" points="87,0 174,50 174,150 87,200 0,150 0,50 87,0" />
          </clipPath>
        </defs>
        <g clipPath="url(#clip-path)">
          <image
            x="0"
            y="0"
            width="100%"
            height="100%"
            preserveAspectRatio="xMidYMid slice"
            href={image} />
        </g>
      </svg> : <svg width="100%" height="100%" viewBox="0 0 175 200" x="0" y="0">
          <polyline id="hexagon" points="87,0 174,50 174,150 87,200 0,150 0,50 87,0" fill={color} />
          <text fill="white" fontSize="74" x="50%" y="125" textAnchor="middle" fontFamily="Roboto" >{initial}</text>
        </svg>}
    </div>
  )
}


