import React from "react"
import './GameLoaderStyles.css'
import LongLogo from "../svg/LongLogo"
import Spinner from "../svg/Spinner"
import { gameLoaderDependencies } from "../../dependencies";
import PropTypes from "prop-types"
import { GameRouter } from '@mrmrs/core';
import TouchableButton from "../../ui/TouchableButton";

export default class GameLoaderScreen extends React.Component {

  static propTypes = {
    href: PropTypes.object.isRequired,
    token: PropTypes.string.isRequired,
    router: PropTypes.instanceOf(GameRouter).isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      startGame: null,
      isInProgress: false,
      isDownloadingVideo: false,
      downloadMessage: null,
      errorMessage: null,
      progress: 0,
    }
    this.presenter = gameLoaderDependencies.createPresenter(this, props.href, props.router, props.token)
  }

  setProgress(progress) {
    this.setState({ progress: progress })
  }

  setStartGame(startGame) {
    this.setState({ startGame: startGame })
  }

  setDownloadFailedMessage(message) {
    this.setState({ errorMessage: message })
  }

  setIsDownloadingVideo(isDownloadingVideo) {
    this.setState({ isDownloadingVideo: isDownloadingVideo })
  }

  setIsInProgress(isInProgress) {
    this.setState({ isInProgress: isInProgress })
  }

  setDownloadMessage(message) {
    this.setState({ downloadMessage: message })
  }

  componentWillDisappear() { }
  componentDidAppear() { }

  componentWillUnmount() {
    this.presenter.unload()
  }

  render() {
    return (
      <div {...this.props} className="backgroundGradient">
        <div className="centreFlexBox">
          <div className="logoDiv">
            <LongLogo />
          </div>
        </div>
        <div className="progressContainer">
          {this.renderProgress()}
          {this.renderError()}
          {this.renderStartButton()}
        </div>
      </div>

    )
  }

  renderProgress() {
    if (this.state.isInProgress) {
      return (
        <div className="downloadContainer">
          <div><p className="downloadText">{this.state.downloadMessage}</p></div>
          {this.renderSpinnerOrProgressBar()}
        </div>
      )
    }
  }

  renderSpinnerOrProgressBar() {
    if (this.state.isDownloadingVideo) {
      return <ProgressBar progress={this.state.progress}></ProgressBar>
    } else {
      return (
        <div className="spinner">
          <Spinner />
        </div>
      )
    }
  }

  renderError() {
    if (this.state.errorMessage) {
      return <div className="retryContainer">
        <p className="retryContainer__errorMessage">{this.state.errorMessage}</p>
        <BorderButton text="Retry" onClick={this.reload} />
      </div>
    }
  }

  reload = () => {
    this.props.router.tracker.trackEvent("game", "Retry")
    this.presenter.reload()
  }

  renderStartButton() {
    if (this.state.startGame) {
      return <div className="startButtonContainer">
        <BorderButton text={"Start"} onClick={this.start} />
      </div>
    }
  }

  start = () => {
    this.props.router.tracker.trackEvent("game", "Start")
    this.state.startGame()
  }
}


function ProgressBar(props) {
  let progress = props.progress * 100
  return <div className="progressBar" {...props}>
    <div className="progressFill" style={{ width: progress + "%" }}></div>
  </div>
}

function BorderButton(props) {
  let text = props.text
  return (
    <TouchableButton className="button" onClick={props.onClick}>
      <p className="buttonText">{text}</p>
    </TouchableButton>
  )
}
