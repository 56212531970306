export default class GameEventHandler {
  constructor(tracker) {
    this.tracker = tracker
  }

  listenForQuestionEvents(router, view) {
    view.onAnswerButtonPressed(() => {
      router.goToAnswerPage()
    })
  }

  listenForAnswerEvents(router, view) {
    view.onVideoAnswerEnded(() => {
      this.tracker.trackEvent('game', 'Video ended')
      router.goToAnswerResultPage()
      view.reset()
      this.unlistenForVideoEvents(view)
    })
    view.onFinishedWatchingButtonPressed(() => {
      this.tracker.trackEvent('game', 'Finished watching clicked')
      router.goToAnswerResultPage()
      view.reset()
      this.unlistenForVideoEvents(view)
    })
  }

  unlistenForVideoEvents(view) {
    view.onVideoAnswerEnded(() => {
      // can be triggered by going into the background
    })
  }

  listenForAnswerResultEvents(router, view, scorePresenter) {
    view.onCorrectAnswerButtonPressed(() => {
      this.tracker.trackEvent('game', 'Correct answer')
      scorePresenter.incrementScore()
      router.goToScorePage()
    })
    view.onIncorrectAnswerButtonPressed(() => {
      this.tracker.trackEvent('game', 'Incorrect answer')
      router.goToScorePage()
    })
    view.onRepeatAnswerButtonPressed(() => {
      this.tracker.trackEvent('game', 'Repeat answer')
      router.goToAnswerPage()
    })
  }

  listenForFinalScoreEvents(router, view) {
    view.onReplayButtonPressed(() => {
      this.tracker.trackEvent('game', 'Replay game')
      router.resetGame()
      router.goToLoadingPage()
    })
  }

  didShowScorePage(animator, questionIndex, presenter) {
    if (questionIndex === -1) {
      animator.animateIn()
    } else {
      presenter.updateScore()
    }
  }
}
