export default class InMemoryStore {
  constructor(storeName, defaultValue) {
    if (InMemoryStore.stores == null) {
      InMemoryStore.stores = {}
    }
    this.storeName = storeName
    this.defaultValue = JSON.stringify(defaultValue)
  }

  create(item) {
    let toStore
    if (item != null) {
      toStore = JSON.stringify(item)
    }
    InMemoryStore.stores[this.storeName] = toStore
  }

  read() {
    const stored = InMemoryStore.stores[this.storeName]
    if (stored != null) {
      return JSON.parse(stored)
    } else if (this.defaultValue != null) {
      return JSON.parse(this.defaultValue)
    }
  }

  update(item) {
    this.create(item)
  }

  delete() {
    this.create(undefined)
  }
}
