import { 
  GameRouter, 
  GetRequestAction, 
  GameLoaderPresenter, 
  VideoDownloaderCoordinator, 
  ConcurrentProgressPresenter,
  fetchDependencies,
  InMemoryStore,
  VideoDownloaderWatcher,
} from '@mrmrs/core';
import VideoDownloader from "../game/loader/VideoDownloader"
import GoogleAnalytics from "../analytics/GoogleAnalytics";

function createAuthFetchRequest(token) {
  return data => fetchDependencies.createAuthBearerFetchRequest(data, token)
}

export const analyticsDependencies = {
  logScreenView: GoogleAnalytics.instance().logScreenView,
  logEvent: GoogleAnalytics.instance().logEvent,
}

export const gameDependencies = {
  createRouter: (config) => new GameRouter(config),
}

export const gameLoaderDependencies = {
  createDownloader: () => new VideoDownloader(() => new XMLHttpRequest(), URL.createObjectURL),
  createWatcher: (subject, callbacks) => new VideoDownloaderWatcher(subject, callbacks),
  createPresenter: (view, href, router, token) => {
    let fetchGame = new GetRequestAction(createAuthFetchRequest(token))
    let downloader = gameLoaderDependencies.createDownloader()
    return new GameLoaderPresenter({
      view: view,
      downloader: new VideoDownloaderCoordinator(downloader, gameLoaderDependencies.createWatcher),
      progressPresenter: ConcurrentProgressPresenter,
      fetchGame: () => fetchGame.execute(href),
      router: router,
      store: new InMemoryStore("null"),
    })
  }
}
