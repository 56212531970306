import React from "react"
import PropTypes from "prop-types"
import "./LandscapeViewStyles.css"
import rotatePhone from "./rotatePhone.svg"

export default class LandscapeView extends React.Component {

  static propTypes = {
    window: PropTypes.object.isRequired,
    minWidth: PropTypes.number.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      windowDimesnion: { width: this.props.window.innerWidth, height: this.props.window.innerHeight }
    }
    this.props.setOnResize && this.props.setOnResize(this.onResize)
    this.props.window.addEventListener("resize", this.onResize)
  }

  componentWillUnmount() {
    this.props.window.removeEventListener("resize", this.onResize)
  }

  onResize = ({ target }) => this.setState({
    windowDimesnion: {
      width: target.innerWidth,
      height: target.innerHeight,
    }
  })

  render() {
    return (
      <div className="landscapeView">
        {this.props.children}
        {this.renderError()}
      </div>
    )
  }

  renderError() {
    if (this.isMinWidthSatisfied() || this.isLandscape()) {
      return
    }
    return (
      <div className="landscapeError">
        <div className="landscapeError__inner">
          <div className="landscapeError__message">Your screen needs to be wider.<br/>Make your browser full screen or rotate your device.</div>
          <img className="landscapeError__image" src={rotatePhone} alt="rotate device"/>
        </div>
      </div>
    )
  }

  isMinWidthSatisfied() {
    return this.state.windowDimesnion.width >= this.props.minWidth
  }

  isLandscape() {
    return this.state.windowDimesnion.width > this.state.windowDimesnion.height
  }
}
