import React from "react"
import PropTypes from "prop-types"
import { gameDependencies, analyticsDependencies } from "../dependencies";
import GameScoreScreen from "./score/GameScoreScreen";
import GameLoaderScreen from "./loader/GameLoaderScreen";
import ScoreAnimator from "./score/ScoreAnimator";
import GameQuestionScreen from "./question/GameQuestionScreen";
import GameAnswerScreen from "./answer/GameAnswerScreen";
import GameAnswerResultScreen from "./answer/GameAnswerResultScreen";
import GameFinalScoreScreen from "./finalScore/GameFinalScoreScreen";
import GameWelcomeAnimationScreen from './welcomeAnimation/GameWelcomeAnimationScreen';

export default class GameScreen extends React.Component {

  static propTypes = {
    token: PropTypes.any.isRequired,
    href: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      errorMessage: null,
      view: null,
    }
    this.createRouter()
  }

  createRouter() {
    let tracker = {
      trackEvent: analyticsDependencies.logEvent,
      trackPageview: analyticsDependencies.logScreenView,
    }
    this.router = gameDependencies.createRouter({
      replacePage: this.replacePage,
      view: this,
      loadGame: () => {},
      rateApp: () => { },
      createScoreAnimator: (finish) => new ScoreAnimator(this.getScoreView(), finish),
      createTracker: () => tracker,
      rateDelay: (fn) => setTimeout(fn, 4000),
    })
  }

  componentDidMount() {
    this.router.goToLoadingPage()
    this.getLoaderView().presenter.load()
  }

  render() {
    return (
      <div className="game">
        <div className="game__container">
          <GameLoaderScreen className="loadingScreen" style={this.getHiddenStyle("loadingScreen")} ref={r => this.loaderView = r} href={this.props.href} router={this.router} token={this.props.token} />
          <GameWelcomeAnimationScreen className="welcomeAnimationScreen" style={this.getHiddenStyle("welcomeAnimationScreen")} ref={r => this.welcomeAnimationView = r} />
          <GameScoreScreen className="scoreScreen" style={this.getHiddenStyle("scoreScreen")} ref={r => this.scoreView = r} />
          <GameQuestionScreen className="questionScreen" style={this.getHiddenStyle("questionScreen")} ref={r => this.questionView = r} />
          <GameAnswerScreen className="answerScreen" style={this.getHiddenStyle("answerScreen")} ref={r => this.answerView = r} />
          <GameAnswerResultScreen className="answerResultScreen" style={this.getHiddenStyle("answerResultScreen")} ref={r => this.answerResultView = r} />
          <GameFinalScoreScreen className="finalScoreScreen" style={this.getHiddenStyle("finalScoreScreen")} ref={r => this.finalScoreView = r} close={this.close} />
        </div>
      </div>
    )
  }

  replacePage = (view) => {
    let oldView = this.getView(this.state.view)
    let newView = this.getView(view)
    if (oldView && oldView.componentWillDisappear) {
      oldView.componentWillDisappear()
    }
    this.setState({ view: view }, () => newView.componentDidAppear && newView.componentDidAppear())
  }

  getHiddenStyle(view) {
    if (view === this.state.view) {
      return {
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        width: "100%",
        height: "100%",
        zIndex: 1,
      }
    }
    return {
      position: "absolute",
      top: "-100%",
      left: "-100%",
      zIndex: 0,
    }
  }

  getView(view) {
    switch (view) {
      case "loadingScreen": return this.loaderView
      case "welcomeAnimationScreen" : return this.welcomeAnimationView
      case "scoreScreen": return this.scoreView
      case "questionScreen": return this.questionView
      case "answerScreen": return this.answerView
      case "answerResultScreen": return this.answerResultView
      case "finalScoreScreen": return this.finalScoreView
      default: return null
    }
  }

  getScoreView = () => this.scoreView
  getWelcomeAnimationView = () => this.welcomeAnimationView
  getLoaderView = () => this.loaderView
  getQuestionView = () => this.questionView
  getAnswerView = () => this.answerView
  getAnswerResultView = () => this.answerResultView
  getFinalScoreView = () => this.finalScoreView
}
