export default class RequestAction {
  constructor(fetchRequestFactory) {
    this.fetchRequestFactory = fetchRequestFactory
  }

  execute(link, payload) {
    return this.createRequest(link.href, payload).execute()
  }

  createRequest(url, payload) {
    throw new Error('Implement me')
  }
}
