export default function addAPIKey(credential, data) {
  if (credential != null) {
    if (data.data == null) {
      data.data = {}
    }
    if (data.data.headers == null) {
      data.data.headers = {}
    }
    data.data.headers['X-API-KEY'] = credential
  }
  return data
}
