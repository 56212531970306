export default class GameFinalScorePresenter {
  constructor(view) {
    this.view = view
  }

  setScore(score, total) {
    this.view.setScore(score)
    this.view.setTotal(total)
  }
}
