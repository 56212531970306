export default class VideoDownloaderCoordinator {
  constructor(downloader, createWatcher) {
    this.downloader = downloader
    this.createWatcher = createWatcher
  }

  startDownloading(urls, listener) {
    this.watcher = this.createWatcher(this.downloader, {
      complete: (r) => {
        this.watcher.stopWatching()
        listener.didComplete(r)
      },
      progress: (p) => listener.didProgress(p),
      error: (e) => listener.didError(e),
    })
    this.downloader.startDownloading(urls)
    this.watcher.startWatching()
  }

  cancel() {
    this.downloader.cancel()
  }
}
