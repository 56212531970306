import React from "react"

export default function ReplayGame() {
  return (
    <svg width="100%" height="100%" viewBox="0 0 26 23" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
      <title>replayGame</title>
      <desc>Created with Sketch.</desc>
      <g id="Redesign-NEW-colours" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Game_final_page-Copy-8" transform="translate(-317.000000, -260.000000)" stroke="#FFFFFF">
          <g id="Group-2" transform="translate(-37.000000, -46.000000)">
            <g id="replayGame" transform="translate(352.000000, 304.000000)">
              <g id="Group-4" transform="translate(14.070931, 13.502544) rotate(206.000000) translate(-14.070931, -13.502544) translate(4.070931, 3.502544)">
                <g id="Group-5">
                  <path d="M1.73334828,15.617468 C3.50951383,18.3167835 6.20583338,19.6664412 9.82230694,19.6664412 C15.2470173,19.6664412 19.6446139,15.2639584 19.6446139,9.83322061 C19.6446139,4.40248283 15.2470173,0 9.82230694,0 C4.39759661,0 3.00721419e-16,4.9166103 0,9.83322061" id="Oval-6" strokeWidth="2.85420007"></path>
                  <polyline id="Path-10" strokeWidth="2.19878122" points="4.99204305 14.8316835 0.658672347 14.8316835 0.658672347 19.7482938"></polyline>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}