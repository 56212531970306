export default class FetchRequest {
  constructor(data) {
    this.data = data
  }

  execute() {
    return fetch(this.data.url, this.data.data).then(async (response) => {
      if (response.ok) {
        return response.json()
      } else {
        const json = await response.json()
        throw json
      }
    })
  }
}
