import React from "react"

export default function ThumbsUp() {
  return (
    <svg width="100%" height="100%" viewBox="0 0 114 151" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
    <title>Yes or no Copy 2</title>
      <desc>Created with Sketch.</desc>
      <g id="Redesign-NEW-colours" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Yes-or-no-Copy-2" transform="translate(-338.000000, -91.000000)">
          <rect id="Rectangle-8" fill="#269AFE" x="0.5546875" y="0" width="568" height="319.5"></rect>
          <g id="Icons" transform="translate(106.000000, 74.000000)">
            <path d="M189,5 L342.3125,5 C358.156169,5 371,17.8438312 371,33.6875 L371,152.3125 C371,168.156169 358.156169,181 342.3125,181 L189,181 L189,5 Z" id="Rectangle-21" fill="#FFFFFF"></path>
            <path d="M233,17 L326.246951,17 C329.85491,17.9988043 332.666232,20.8287499 334.680916,25.4898368 C337.702942,32.481467 347.626686,84.4192919 346.968774,89.9127157 C346.310862,95.4061394 340.640775,99.4013567 336.149833,100.899563 C333.155871,101.898368 321.630696,101.898368 301.574308,100.899563 C305.995562,107.891193 308.705183,112.55228 309.70317,114.882824 C311.200151,118.378639 312.198138,127.367878 309.204176,131.363095 C306.210215,135.358312 298.022072,139.852932 293.735374,138.860613 C289.448676,137.868295 259.709527,102.39777 256.940017,100.899563 C255.093677,99.9007589 247.113671,99.4342378 233,99.5 L233,17 Z M241.840036,27.4874454 L241.840036,91.4109221 L252.817896,91.4109221 L252.817896,27.4874454 L241.840036,27.4874454 Z M263.795756,27.4874454 L263.795756,93.4085308 C284.904212,114.74441 296.048403,125.731257 297.228329,126.369073 C298.085835,126.832604 299.224304,124.371465 299.224304,119.377443 C299.224304,114.383422 296.295428,113.384617 293.735374,107.391791 C293.311635,106.399862 291.149329,100.573503 287.248457,89.9127157 L333.155871,89.9127157 C334.835276,89.9127157 335.833263,89.0803787 336.149833,87.4157048 C336.889935,83.5238974 326.079984,29.6309265 323.386341,27.4874454 C321.590579,26.058458 301.72705,26.058458 263.795756,27.4874454 Z" id="thumbsUp" fill="#269AFE" fillRule="nonzero" transform="translate(290.000000, 78.000000) scale(-1, 1) rotate(-180.000000) translate(-290.000000, -78.000000) "></path>
            <rect id="Rectangle-22" stroke="#FFFFFF" strokeWidth="5.7375" x="2.86875" y="2.86875" width="370.2625" height="178.2625" rx="34.425"></rect>
          </g>
          <g id="thumbsUp" transform="translate(339.000000, 91.000000)" fillRule="nonzero" stroke="#FFFFFF">
            <rect id="Rectangle" x="0.5" y="0.5" width="113" height="150"></rect>
          </g>
        </g>
      </g>
    </svg>
  )
}
