import React from "react"

export default function NextArrow() {
  return (
    <svg width="100%" height="100%" viewBox="0 0 12 19" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
      <title>Group</title>
      <desc>Created with Sketch.</desc>
      <g id="Redesign-NEW-colours" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Question-1-Copy-2" transform="translate(-516.000000, -148.000000)" fill="#FFFFFF" fillRule="nonzero">
          <g id="Group" transform="translate(511.000000, 150.000000)">
            <polygon id="arrow" transform="translate(7.750000, 7.750000) rotate(135.000000) translate(-7.750000, -7.750000) " points="4.5 4.5 4.5 14 1.5 14 1.5 1.5 14 1.5 14 4.5"></polygon>
          </g>
        </g>
      </g>
    </svg>
  )
}
