export default function getToken(url) {
  let params = getParams(url)
  let token = params["token"]
  let path = params["path"]
  if (token && path) {
    return {
      token: token,
      path: path
    }
  } else {
    throw "No game was found. Make sure your link is right!"
  }
}

// Support older browsers than URL.searchParams
function getParams(url) {
  var vars = {};
  url.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m, key, value) => {
    vars[key] = decodeURIComponent(value);
  });
  return vars;
}

