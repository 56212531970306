import FetchRequest from '../network/FetchRequest'
import LinksToActions from '../links/LinksToActions'
import AuthorizeRequest from '../links/AuthorizeRequest'
import AddJSONHeaders from '../links/AddJSONHeaders'
import addAPIKey from '../links/addAPIKey'
import AsyncButtonPresenter from '../ui/AsyncButtonPresenter'
import PrependBaseURL from '../links/PrependBaseURL'
import {AddCors} from '../network/AddCors'

export const fetchDependencies = {
  createFetchRequest: (data) =>
    new FetchRequest(fetchDependencies.buildRequest(data)),
  createAuthFetchRequest: (data, user) =>
    new FetchRequest(
      AuthorizeRequest(fetchDependencies.buildRequest(data), user.token),
    ),
  createAuthBearerFetchRequest: (data, token) =>
    new FetchRequest(
      AuthorizeBearerRequest(fetchDependencies.buildRequest(data), token),
    ),
  buildRequest: (data) =>
    AddCors(
      fetchDependencies.useCors,
      addAPIKey(
        fetchDependencies.apiKey,
        AddJSONHeaders(PrependBaseURL(fetchDependencies.baseURL, data)),
      ),
    ),
  baseURL: '',
  apiKey: '',
  useCors: false,
}

export const actionsDependencies = {
  createLinksToActions: () => {
    return new LinksToActions()
  },
  createAsyncButtonPresenter: (view, onPress) =>
    new AsyncButtonPresenter(view, onPress),
}

function AuthorizeBearerRequest(data, token) {
  data.data.headers.Authorization = `Bearer ${token}`
  data.data.headers['Access-Control-Allow-Origin'] = '*'
  data.data.headers['Access-Control-Allow-Methods'] =
    'POST, PUT, DELETE, GET, OPTIONS'
  data.data.headers['Access-Control-Request-Method'] = '*'
  data.data.headers['Access-Control-Allow-Headers'] =
    'Origin, X-Requested-With, Content-Type, Accept, Authorization'
  return data
}
