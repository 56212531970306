import React from "react"
import "./TouchableButtonStyles.css"

export default class TouchableButton extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      extraStyle: ""
    }
  }

  down = () => this.setState({ extraStyle: "touchable_button--down" })
  up = () => this.setState({ extraStyle: "" })

  render() {
    return (
      <div {...this.props}
        className={`touchable_button ${this.props.className} ${this.state.extraStyle}`}
        onMouseDown={this.down}
        onMouseUp={this.up}
        onMouseLeave={this.up}
        onTouchStart={this.down}
        onTouchCancel={this.up}
        onTouchEnd={this.up}>
        {this.props.children}
      </div>
    )
  }
}
