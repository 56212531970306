import RequestAction from '../links/RequestAction'

export default class GetRequestAction extends RequestAction {
  createRequest(url, payload) {
    let queryString = ''
    if (payload) {
      queryString =
        '?' +
        Object.keys(payload)
          .map((key) => {
            return `${key}=${payload[key]}`
          })
          .join('&')
    }
    const data = {
      url: `${url}${queryString}`,
      data: {
        method: 'GET',
      },
    }
    return this.fetchRequestFactory(data)
  }
}
