import React from "react"

export default function ThumbsDown() {
  return (
    <svg width="100%" height="100%" viewBox="0 0 114 151" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
    <title>thumbsDown</title>
      <desc>Created with Sketch.</desc>
      <g id="Redesign-NEW-colours" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Yes-or-no-Copy-2" transform="translate(-150.000000, -91.000000)" fillRule="nonzero">
          <g id="thumbsDown" transform="translate(150.000000, 91.000000)">
            <path d="M-3.41060513e-13,29 L93.246951,29 C96.8549103,29.9988043 99.6662319,32.8287499 101.680916,37.4898368 C104.702942,44.481467 114.626686,96.4192919 113.968774,101.912716 C113.310862,107.406139 107.640775,111.401357 103.149833,112.899563 C100.155871,113.898368 88.6306959,113.898368 68.5743078,112.899563 C72.995562,119.891193 75.7051827,124.55228 76.70317,126.882824 C78.2001509,130.378639 79.1981382,139.367878 76.2041764,143.363095 C73.2102146,147.358312 65.0220719,151.852932 60.7353737,150.860613 C56.4486756,149.868295 26.7095273,114.39777 23.9400171,112.899563 C22.093677,111.900759 14.1136713,111.434238 4.92406116e-12,111.5 L-3.41060513e-13,29 Z M8.84003586,39.4874454 L8.84003586,103.410922 L19.8178958,103.410922 L19.8178958,39.4874454 L8.84003586,39.4874454 Z M30.7957557,38.4886411 L30.7957557,104.409726 C51.9042122,125.745605 63.0484033,136.732453 64.2283292,137.370269 C65.0858354,137.833799 66.2243037,135.372661 66.2243037,130.378639 C66.2243037,125.384617 63.295428,124.385813 60.7353737,118.392987 C60.3116348,117.401057 58.1493291,111.574699 54.2484565,100.913911 L100.155871,100.913911 C101.835276,100.913911 102.833263,100.081574 103.149833,98.4169005 C103.889935,94.525093 93.0799839,40.6321222 90.3863408,38.4886411 C88.5905787,37.0596537 68.7270503,37.0596537 30.7957557,38.4886411 Z" fill="#FFFFFF" transform="translate(57.000000, 90.000000) scale(-1, -1) rotate(-180.000000) translate(-57.000000, -90.000000) "></path>
            <rect id="Rectangle" stroke="none" x="0.5" y="0.5" width="113" height="150"></rect>
          </g>
        </g>
      </g>
    </svg>
  )
}
