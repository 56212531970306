import React from "react"
import './GameQuestionStyles.css'
import TouchableButton from "../../ui/TouchableButton";
import NextArrow from "../svg/NextArrow";

export default class GameQuestionScreen extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      text: "placeholder text",
      revealAnswer: () => { },
    }
  }

  componentWillDisappear() { }
  componentDidAppear() { }

  setText(text) {
    this.setState({ text: text })
  }

  onAnswerButtonPressed(revealAnswer) {
    this.setState({ revealAnswer: revealAnswer })
  }

  render() {
    return (
      <div {...this.props} className="gameQuestion__backgroundGradient">
        <div className="gameQuestion__textContainer">
          <div className="gameQuestion__text">{this.state.text}
          </div>
        </div>
        <TouchableButton className="gameQuestion__clickableArea" onClick={this.state.revealAnswer}>
          <div className="gameQuestion__nextArrow">
            <NextArrow />
          </div>
        </TouchableButton>
      </div>
    )
  }
}