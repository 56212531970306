import React from "react"
import './GameScoreStyles.css'
import CompletePoint from "../svg/CompletePoint"
import IncompletePoint from "../svg/IncompletePoint"
import PlayerHexagon from "../../ui/PlayerHexagon";
import { playerColor, uploaderPlayerColor } from '@mrmrs/core';

export default class GameScoreScreen extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      score: 0,
      maxScore: 3, // Prevents flicker by loading timeline before screen is shown
      uploaderInitial: "",
      subjectInitial: "",
      questionIndex: 0,
      subjectClassName: "",
      uploaderClassName: "",
      onSubjectAnimationEnd: () => { },
      onUploaderAnimationEnd: () => { },
    }
  }

  setScore(score) {
    this.setState({ score: score })
  }

  setMaxScore(maxScore) {
    this.setState({ maxScore: maxScore })
  }

  setQuestionIndex(index) {
    this.setState({ questionIndex: index })
  }

  setSubjectInitial(initial) {
    this.setState({ subjectInitial: initial })
  }

  setUploaderInitial(initial) {
    this.setState({ uploaderInitial: initial })
  }

  setSubjectImage(image) {
    this.setState({ subjectImage: image })
  }

  setUploaderImage(image) {
    this.setState({ uploaderImage: image })
  }

  setAppearSound() {

  }

  componentWillDisappear() { }
  componentDidAppear() { }

  render() {
    return (
      <div {...this.props} className="gameScore">
        <div className="gameScore__centredContainer">
          {this.getScoreMarkers()}
          <ScoreMarker className="score_marker" animationClassName={this.state.uploaderClassName} onAnimationEnd={this.state.onUploaderAnimationEnd} initial={this.state.uploaderInitial} image={this.state.uploaderImage} isComplete={this.state.questionIndex === this.state.maxScore} isStart={false} isEnd={true} isSubject={false} />
        </div>
      </div>
    )
  }

  getScoreMarkers() {
    let scoreMarkers = []
    for (var i = 0; i < this.state.maxScore; i++) {
      let isComplete = i <= this.state.questionIndex
      let isStart = i === 0
      let initial = i === this.state.score ? this.state.subjectInitial : null
      let animationClassName = i === this.state.score ? this.state.subjectClassName : null
      let onAnimationEnd = i === this.state.score ? this.state.onSubjectAnimationEnd : () => { }
      scoreMarkers.push(<ScoreMarker key={`track${i}`} className="score_marker" isComplete={isComplete} isStart={isStart} isEnd={false} initial={initial} image={this.state.subjectImage} isSubject={true} animationClassName={animationClassName} onAnimationEnd={onAnimationEnd} />)
    }
    return scoreMarkers
  }
}


function getLineClassName(isStart, isEnd) {
  let lineClassName = ""

  if (isStart) {
    lineClassName = "scoreMarker__trackItemLineContainer__start"
  }
  else if (isEnd) {
    lineClassName = "scoreMarker__trackItemLineContainer__end"
  }
  else {
    lineClassName = "scoreMarker__trackItemLineContainer__main"
  }

  return lineClassName
}

function ScoreMarker(props) {
  return <div className="scoreMarker">
    {renderHexagon(props)}
    <div className="scoreMarker__trackItem">
      <div className="scoreMarker__trackItemDotContainer">
        <div className="scoreMarker__trackItemDot">
          {props.isComplete ? <CompletePoint /> : <IncompletePoint />}
        </div>
      </div>
      <div className={getLineClassName(props.isStart, props.isEnd)}>
        <div className="scoreMarker__trackItemLine"></div>
      </div>
    </div>
  </div>
}

function renderHexagon(props) {
  if (props.initial != null) {
    return (
      <div
        className={`scoreMarker__hexagon ${props.animationClassName}`}
        onAnimationEnd={props.onAnimationEnd}>
        <div className="scoreMarker__centerHexagon">
          <PlayerHexagon
            image={props.image}
            color={props.isSubject ? playerColor : uploaderPlayerColor}
            initial={props.initial}
            className={props.isSubject ? "subjectHexagon" : "uploaderHexagon"} />
        </div>
      </div>
    )
  }
}



