import React from "react"
import './GameFinalScoreStyles.css'
import StarBadge from '../svg/StarBadge'
import ReplayGame from '../svg/ReplayGame'
import PlayerHexagon from "../../ui/PlayerHexagon";
import TouchableButton from "../../ui/TouchableButton";
import Confetti from 'react-dom-confetti';
import { playerColor } from '@mrmrs/core'
import Sound from 'react-sound'

export default class GameFinalScoreScreen extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      initial: null,
      name: null,
      score: 0,
      maxScore: 0,
      replay: () => { },
      animationClass: "",
      activateConfetti: false,
      welcomeMusic: undefined
    }
  }

  componentWillDisappear() {
    this.setState({
      activateConfetti: false,
      welcomeMusic: undefined
    })
  }

  componentDidAppear() {
    this.setState({
      animationClass: "finalScoreScreen__componentsContainer--animateIn",
      activateConfetti: true,
    })

  }

  onReplayButtonPressed(fn) {
    this.setState({ replay: fn })
  }

  setScore(score) {
    this.setState({ score: score })
  }

  setTotal(total) {
    this.setState({ maxScore: total })
  }

  setSubjectInitial(initial) {
    this.setState({ initial: initial })
  }

  setSubjectName(name) {
    this.setState({ name: name })
  }

  setSubjectImage(image) {
    this.setState({ subjectImage: image })
  }

  onAnimationEnd = () => {
    this.setState({ animationClass: "" })
  }

  setWelcomeMusic = (music) => {
    this.setState({ welcomeMusic: music })
  }

  render() {
    return (
      <div {...this.props} className="finalScoreScreen__background">
        {this.state.welcomeMusic && <Sound className="welcome_music" url={this.state.welcomeMusic} playStatus={Sound.status.PLAYING}/>}
        <div className={`finalScoreScreen__componentsContainer ${this.state.animationClass}`} onAnimationEnd={this.onAnimationEnd}>
          <div className="componentsContainer__wahoo">
            WAHOO!
          </div>
          <div className="componentsContainer__mainScoreContainer">
            <div className="mainScoreContainer__starBadge">
              <StarBadge />
            </div>
            <div className="mainScoreContainer__subjectScoreContainer">
              <div className="subjectScoreContainer__hexagon">
                <PlayerHexagon
                  className="subjectHexagon"
                  initial={this.state.initial}
                  color={playerColor}
                  image={this.state.subjectImage}
                />
              </div>
              <div className="subjectScoreContainer__nameScoreContainer">
                <div className="nameScoreContainer__niceOneText">Nice one, {this.state.name}!</div>
                <div className="nameScoreContainer__score">{this.state.score} / {this.state.maxScore}</div>
                <div className="nameScoreContainer__scoreText"><p>Great score!</p></div>
              </div>
            </div>
          </div>
          <div className="mainScoreContainer__iconsContainer">
            <TouchableButton className="iconsContainer__icons" onClick={this.state.replay}>
              <div className="iconsContainer__icon">
                <ReplayGame />
              </div>
            </TouchableButton>
          </div>
        </div>
        <div className="confettiAnimation">{this.renderConfetti()}</div>
      </div>
    )
  }

  renderConfetti() {
    const config = {
      angle: 90,
      spread: 120,
      startVelocity: 70,
      elementCount: 100,
      dragFriction: 0.1,
      duration: 4450,
      delay: 0,
      width: "10px",
      height: "10px",
      colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"]
    };
    return <Confetti active={this.state.activateConfetti} config={config} />
  }
}

