export default class ScorePresenter {
  constructor(object) {
    this.score = 0
    this.lastUpdatedScore = 0
    this.getMaxScore = object.getMaxScore
    this.animator = object.animator
  }

  incrementScore() {
    if (this.getScore() < this.getMaxScore()) {
      this.score++
    }
  }

  getScore() {
    return this.score
  }

  updateScore() {
    if (this.lastUpdatedScore === this.getScore()) {
      this.animator.animateShake(this.getScore())
    } else if (this.getMaxScore() === this.getScore()) {
      this.animator.animateMaxScore(this.getScore())
    } else {
      this.animator.animateMove(this.getScore())
    }
    this.lastUpdatedScore = this.getScore()
  }
}
