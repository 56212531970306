export default class APIGame {
  constructor(json) {
    this.json = json
  }

  getURLs() {
    return this.getQuestions()
      .map((q) => q.getAnswer().getURL())
      .concat(Object.values(this.getSounds()))
      .concat(this.getSubject().image)
      .concat(this.getUploader().image)
      .filter((item) => item)
  }

  getSounds() {
    const result = {}
    if (this.json.sounds == null) {
      return result
    }
    Object.keys(this.json.sounds).forEach((k) => {
      const sound = this.json.sounds[k]
      if (typeof sound === 'string' && sound !== '') {
        result[k] = sound
      }
    })
    return result
  }

  getSubject() {
    return this.json.subject
  }

  getUploader() {
    return this.json.uploader
  }

  getQuestions() {
    return this.json._embedded.question.map((q) => new APIQuestion(q))
  }
}

class APIQuestion {
  constructor(json) {
    this.json = json
  }

  getText() {
    return this.json.text
  }

  getAnswer() {
    return new APIAnswer(this.json._embedded.answer)
  }
}

class APIAnswer {
  constructor(json) {
    this.json = json
  }

  getURL() {
    return this.json.transcodedURL
  }
}
