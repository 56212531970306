import React from 'react';
import Animated from 'animated/lib/targets/react-dom';
import Easing from 'animated/lib/Easing';
import Sound from 'react-sound'
import PlayerHexagon from '../../ui/PlayerHexagon';
import './GameWelcomeAnimationStyles.css'
import { playerColor, uploaderPlayerColor } from '@mrmrs/core'


export default class GameWelcomeAnimationScreen extends React.Component {

  state = {
    skipAnimation: () => { },
    welcomeMusic: undefined,
    appearSound: undefined,
    subjectName: "",
    uploaderName: "",
    subjectInitial: "",
    uploaderInitial: "",
    subjectImage: undefined,
    uploaderHexagon: undefined,
    animated_state_1: new Animated.Value(0),
    animated_state_2: new Animated.Value(0),
    animated_state_3: new Animated.Value(0),
    animated_state_4: new Animated.Value(0),
    animated_state_5: new Animated.Value(0),
    animated_state_6: new Animated.Value(0),
    animated_state_7: new Animated.Value(0),
    animated_state_8: new Animated.Value(0),
    animated_state_9: new Animated.Value(0),
    animated_state_10: new Animated.Value(0),
    animated_state_11: new Animated.Value(0),
    animated_state_12: new Animated.Value(0),
  }

  setWelcomeMusic = (music) => {
    this.setState({ welcomeMusic: music })

  }

  setAppearSound = (sound) => {
    this.setState({ appearSound: sound })
  }

  setSubjectName = (name) => {
    this.setState({ subjectName: name })
  }

  setUploaderName = (name) => {
    this.setState({ uploaderName: name })
  }

  setSubjectInitial = (initial) => {
    this.setState({ subjectInitial: initial })
  }

  setUploaderInitial = (initial) => {
    this.setState({ uploaderInitial: initial })
  }

  setSubjectImage = (image) => {
    this.setState({ subjectImage: image })
  }

  setUploaderImage = (image) => {
    this.setState({ uploaderImage: image })
  }

  componentWillDisappear = () => {
    this.setState({
      welcomeMusic: undefined,
      animated_state_7: new Animated.Value(0),
    })
  }


  animate(callback) {

    this.setState({ skipAnimation: callback })

    const animation_1_bride_in = Animated.timing(
      this.state.animated_state_1,
      {
        toValue: 1,
        easing: Easing.elastic(2),
        duration: 2000,
        delay: 1500,
        useNativeDriver: true,
      })

    const animation_2_bride_out = Animated.timing(
      this.state.animated_state_1,
      {
        toValue: 0,
        easing: Easing.elastic(1),
        duration: 500,
        delay: 1000,
        useNativeDriver: true,
      })

    const animation_3_howWell_in = Animated.timing(
      this.state.animated_state_2,
      {
        toValue: 1,
        easing: Easing.elastic(1.5),
        duration: 2000,
        delay: 500,
        useNativeDriver: true,
      })

    const animation_4_howWell_out = Animated.timing(
      this.state.animated_state_2,
      {
        toValue: 0,
        easing: Easing.elastic(1),
        duration: 500,
        delay: 1000,
        useNativeDriver: true,
      })

    const animation_5_groom_in = Animated.timing(
      this.state.animated_state_3,
      {
        toValue: 1,
        easing: Easing.elastic(2),
        duration: 2000,
        useNativeDriver: true,
      })

    const animation_6_groom_out = Animated.timing(
      this.state.animated_state_3,
      {
        toValue: 0,
        easing: Easing.elastic(0.5),
        duration: 500,
        delay: 1000,
        useNativeDriver: true,
      })

    const animation_7_bridePhoto_in = Animated.timing(
      this.state.animated_state_4,
      {
        toValue: 1,
        easing: Easing.elastic(2),
        duration: 1000,
        useNativeDriver: true,
      })

    const animation_8_groomPhoto_in = Animated.timing(
      this.state.animated_state_5,
      {
        toValue: 1,
        easing: Easing.elastic(2),
        duration: 1000,
        useNativeDriver: true,
      })

    const animation_11_bridePhoto_out = Animated.timing(
      this.state.animated_state_4,
      {
        toValue: 0,
        easing: Easing.elastic(2),
        duration: 2000,
        delay: 500,
        useNativeDriver: true,
      })

    const animation_12_groomPhoto_out = Animated.timing(
      this.state.animated_state_5,
      {
        toValue: 0,
        easing: Easing.elastic(2),
        duration: 2000,
        delay: 500,
        useNativeDriver: true,
      })

    const animation_9_questionmark_in = Animated.timing(
      this.state.animated_state_6,
      {
        toValue: 1,
        easing: Easing.elastic(2),
        duration: 1500,
        useNativeDriver: true,
      })

    const animation_13_questionmark_out = Animated.timing(
      this.state.animated_state_6,
      {
        toValue: 0,
        easing: Easing.elastic(2),
        duration: 2000,
        delay: 500,
        useNativeDriver: true,
      })

    const animation_10_questionmark_rotate = Animated.timing(
      this.state.animated_state_7,
      {
        toValue: 1,
        easing: Easing.elastic(2),
        duration: 6000,
        useNativeDriver: true,
      })

    const animation_14_findOut_in = Animated.timing(
      this.state.animated_state_8,
      {
        toValue: 1,
        easing: Easing.elastic(1.8),
        duration: 2000,
        useNativeDriver: true,
      })

    const animation_15_findOut_out = Animated.timing(
      this.state.animated_state_8,
      {
        toValue: 0,
        easing: Easing.elastic(2),
        duration: 1000,
        useNativeDriver: true,
      })

    const animation_16_3_in = Animated.timing(
      this.state.animated_state_9,
      {
        toValue: 1,
        easing: Easing.elastic(2),
        duration: 1200,
        useNativeDriver: true,
      })

    const animation_17_3_out = Animated.timing(
      this.state.animated_state_9,
      {
        toValue: 0,
        easing: Easing.elastic(2),
        duration: 500,
        delay: 600,
        useNativeDriver: true,
      })

    const animation_18_2_in = Animated.timing(
      this.state.animated_state_10,
      {
        toValue: 1,
        easing: Easing.elastic(2),
        duration: 1200,
        useNativeDriver: true,
      })

    const animation_19_2_out = Animated.timing(
      this.state.animated_state_10,
      {
        toValue: 0,
        easing: Easing.elastic(2),
        duration: 500,
        delay: 600,
        useNativeDriver: true,
      })

    const animation_20_1_in = Animated.timing(
      this.state.animated_state_11,
      {
        toValue: 1,
        easing: Easing.elastic(2),
        duration: 1200,
        useNativeDriver: true,
      })

    const animation_21_1_out = Animated.timing(
      this.state.animated_state_11,
      {
        toValue: 0,
        easing: Easing.elastic(2),
        duration: 500,
        delay: 600,
        useNativeDriver: true,
      })

    const animation_22_luck_in = Animated.timing(
      this.state.animated_state_12,
      {
        toValue: 1,
        easing: Easing.elastic(2),
        duration: 2000,
        delay: 500,
        useNativeDriver: true,
      })

    const animation_23_luck_out = Animated.timing(
      this.state.animated_state_12,
      {
        toValue: 0,
        easing: Easing.elastic(2),
        duration: 500,
        delay: 500,
        useNativeDriver: true,
      })

    animation_1_bride_in.start(() => {
      Animated.sequence([
        animation_2_bride_out,
        animation_3_howWell_in,
        animation_4_howWell_out,
        animation_5_groom_in,
        animation_6_groom_out,
      ]).start(() => {
        new Audio(this.state.appearSound).play()
        Animated.sequence([
          animation_7_bridePhoto_in
        ]).start(() => {
          new Audio(this.state.appearSound).play()
          Animated.sequence([
            animation_8_groomPhoto_in,
            animation_9_questionmark_in,
            animation_10_questionmark_rotate,
            Animated.parallel([
              animation_11_bridePhoto_out,
              animation_12_groomPhoto_out,
              animation_13_questionmark_out
            ]),
            animation_14_findOut_in,
            animation_15_findOut_out,
            animation_16_3_in,
            animation_17_3_out,
            animation_18_2_in,
            animation_19_2_out,
            animation_20_1_in,
            animation_21_1_out,
            animation_22_luck_in,
            animation_23_luck_out,
          ]).start(({ finished }) => finished && callback())
        })
      })
    })
  }

  render() {

    const scaleText = this.state.animated_state_1.interpolate({
      inputRange: [0, 0.8, 1],
      outputRange: [0.16, 0.86, 1]
    })

    const scaleText2 = this.state.animated_state_2.interpolate({
      inputRange: [0, 0.8, 1],
      outputRange: [0.16, 0.67, 0.9]
    })

    const scaleText3 = this.state.animated_state_3.interpolate({
      inputRange: [0, 0.8, 1],
      outputRange: [0.16, 0.86, 1]
    })

    const scaleHex1 = this.state.animated_state_4.interpolate({
      inputRange: [0, 1],
      outputRange: [0.5, 1.2]
    })

    const scaleHex2 = this.state.animated_state_5.interpolate({
      inputRange: [0, 1],
      outputRange: [0.5, 1.2]
    })

    const scaleQuestionMark = this.state.animated_state_6.interpolate({
      inputRange: [0, 1],
      outputRange: [0.07, 0.83]
    })

    const scaleText4 = this.state.animated_state_8.interpolate({
      inputRange: [0, 1],
      outputRange: [0.07, 0.73]
    })

    const scaleText5 = this.state.animated_state_9.interpolate({
      inputRange: [0, 1],
      outputRange: [0.07, 1.67]
    })

    const scaleText6 = this.state.animated_state_10.interpolate({
      inputRange: [0, 1],
      outputRange: [0.07, 1.67]
    })

    const scaleText7 = this.state.animated_state_11.interpolate({
      inputRange: [0, 1],
      outputRange: [0.07, 1.67]
    })

    const scaleText8 = this.state.animated_state_12.interpolate({
      inputRange: [0, 1],
      outputRange: [0.07, 1]
    })

    return (
      <div {...this.props} className="welcomeAnimation">
        {this.state.welcomeMusic && <Sound key="test" className="welcomeAnimation__welcome_music" url={this.state.welcomeMusic} playStatus={Sound.status.PLAYING} />}
        <div className="welcomeAnimation__skipAnimationButton"><div className="button" onClick={this.state.skipAnimation} /></div>
        <Animated.div
          className="welcomeAnimation__text"
          style={{
            opacity: this.state.animated_state_1,
            transform: [{
              scale: scaleText
            }]
          }}>
          <div className="welcomeAnimation__subjectName">{this.state.subjectName}</div>
        </Animated.div>
        <Animated.div
          className="welcomeAnimation__text"
          style={{
            opacity: this.state.animated_state_2,
            transform: [{
              scale: scaleText2
            }]
          }}>
          how well do you know
        </Animated.div>
        <Animated.div
          className="welcomeAnimation__text"
          style={{
            opacity: this.state.animated_state_3,
            transform: [{
              scale: scaleText3
            }]
          }}>
          <div className="welcomeAnimation__uploaderName">{this.state.uploaderName}</div>
        </Animated.div>
        <Animated.div
          className="welcomeAnimation__text"
          style={{
            opacity: this.state.animated_state_8,
            transform: [{
              scale: scaleText4
            }]
          }}>let's find out in</Animated.div>
        <Animated.div
          className="welcomeAnimation__text welcomeAnimation__numbers"
          style={{
            opacity: this.state.animated_state_9,
            transform: [{
              scale: scaleText5
            }]
          }}>3</Animated.div>
        <Animated.div
          className="welcomeAnimation__text welcomeAnimation__numbers"
          style={{
            opacity: this.state.animated_state_10,
            transform: [{
              scale: scaleText6
            }]
          }}>2</Animated.div>
        <Animated.div
          className="welcomeAnimation__text welcomeAnimation__numbers"
          style={{
            opacity: this.state.animated_state_11,
            transform: [{
              scale: scaleText7
            }]
          }}>1</Animated.div>
        <Animated.div
          className="welcomeAnimation__text"
          style={{
            opacity: this.state.animated_state_12,
            transform: [{
              scale: scaleText8
            }]
          }}>Good luck!</Animated.div>
        <div className="welcomeAnimation__hexagonContainer">
          <Animated.div
            className="welcomeAnimation__hexagon"
            style={{
              opacity: this.state.animated_state_4,
              transform: [{
                scale: scaleHex1
              }]
            }}>
            <PlayerHexagon className="welcomeAnimation__subjectHexagon" initial={this.state.subjectInitial} image={this.state.subjectImage} color={playerColor} />
          </Animated.div>
          <Animated.div
            className="welcomeAnimation__text welcomeAnimation__questionMark"
            style={{
              opacity: this.state.animated_state_6,
              transform: [{
                scale: scaleQuestionMark
              },
              {
                rotate: this.state.animated_state_7.interpolate({
                  inputRange: [0, 1],
                  outputRange: ['0deg', '1440deg']
                })
              }]
            }}>?</Animated.div>
          <Animated.div
            className="welcomeAnimation__hexagon"
            style={{
              opacity: this.state.animated_state_5,
              transform: [{
                scale: scaleHex2
              }]
            }}>
            <PlayerHexagon className="welcomeAnimation__uploaderHexagon" initial={this.state.uploaderInitial} image={this.state.uploaderImage} color={uploaderPlayerColor} />
          </Animated.div>
        </div>
      </div>
    )
  }
}

