export default class VideoDownloader {

  constructor(requestFactory, createObjectURL) {
    this.requestFactory = requestFactory
    this.createObjectURL = createObjectURL
  }

  startDownloading(urls) {
    this.urls = urls
    this.progress = {}
    this.response = {}
    this.error = {}
    this.index = 0
    this.tasks = []
    urls.forEach(url => {
      this.progress[url] = {
        loaded: 0,
        total: 1
      }
      this.response[url] = null
      this.error[url] = null
    })
    this.downloadAll()
  }

  downloadAll() {
    this.urls.forEach(url => {
      this.sendRequest(url)
    })
  }

  sendRequest(url) {
    const request = this.requestFactory()
    request.addEventListener("load", e => {
      this.handleLoad(request, e, url)
    })
    request.addEventListener("error", () => {
      this.didError(url, true)
    })
    request.addEventListener("progress", e => {
      this.didProgress(e, url)
    })
    this.tasks.push(request)
    request.open("GET", url)
    request.responseType = "blob"
    request.send()
  }

  handleLoad(request, event, url) {
    if (request.status >= 200 && request.status < 300) {
      this.didLoad(event, url, request)
    } else {
      this.didError(url, { message: `Expected a 2xx status code but received ${request.status}` })
    }
  }

  cancel() {
    this.tasks.forEach(t => t.abort())
  }

  didLoad(event, url, xhr) {
    this.response[url] = this.createObjectURL(xhr.response)
    this.didProgress(event, url)
  }

  didProgress(event, url) {
    this.progress[url] = event
  }

  didError(url, error) {
    if (error == null) {
      error = { message: "The download failed unexpectedly. Please try again later" }
    }
    this.error[url] = error
  }
}
