export default function ConcurrentProgressPresenter(view, progress) {
  const percentages = calculatePercentages(progress)
  if (percentages.length === 0) {
    return
  }
  const sum = percentages.reduce((a, b) => a + b, 0)
  const average = sum / percentages.length
  view.setProgress(average)
  view.setDownloadMessage(`Downloading videos ${Math.round(average * 100)}%`)
}

function calculatePercentages(progress) {
  const percent = []
  for (const prop in progress) {
    const p = progress[prop]
    if (isNaN(p.total) || p.total === 0) {
      percent.push(0)
    } else {
      percent.push(p.loaded / p.total)
    }
  }
  return percent
}
