import React from "react"
import discreetArrow from "../../images/discreetArrow.png"
import "./GameAnswerScreenStyles.css"
import TouchableButton from "../../ui/TouchableButton";

export default class GameAnswerScreen extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      source: null,
      onEnded: () => { },
      onSkip: () => { },
    }
  }

  componentWillDisappear() { }
  componentDidAppear() { }

  play(url) {
    this.setState({ source: url })
  }

  onVideoAnswerEnded(fn) {
    this.setState({ onEnded: fn })
  }

  onFinishedWatchingButtonPressed(fn) {
    this.setState({ onSkip: fn })
  }

  reset() {
    this.setState({ source: null })
  }

  render() {
    return (
      <div {...this.props} className="answerPage">
        {this.renderVideo()}
        <TouchableButton className="answer_page__skip_button" onClick={this.state.onSkip}>
          <img src={discreetArrow} className="answer_page__skip_button_image" alt="skip" />
        </TouchableButton>
      </div>
    )
  }

  renderVideo() {
    if (this.state.source != null) {
      return (
        <video className="answer_page__video" onEnded={this.state.onEnded} autoPlay playsInline>
          <source className="answer_page__video_source" src={this.state.source} type="video/mp4"></source>
          Your browser does not support mp4 videos
        </video>
      )
    }
  }
}
