export * from './dependencies'
export * from './ui/device'
export * from './ui/Strings'
export * from './network/FetchRequestTestHelper'
export * from './links/ActionTestHelper'

export const primaryColor = '#F84B77'
export const primaryColorApplyingDarkModalBackgroundAlpha = '#952D47'
export const lightModalBackgroundColor = '#FFFFFFDD'
export const darkModalBackgroundColor = '#00000066'
export const white = '#FFFFFF'
export const offWhite = '#FCFDFE'
export const primaryBackgroundColor = '#F6F7FA'
export const secondaryBackgroundColor = 'white'
export const primaryTextColor = '#4A4A4A'
export const navigationButtonColor = '#979797'
export const lineColor = '#979797'
export const navigationTitleColor = '#4A4A4A'
export const lineGrey = '#9B9B9B'
export const dotColor = '#947B93'
export const grey1 = '#EDF4FA'
export const grey2 = '#D7DBE7'
export const primaryGradientColors = ['#F84B77', '#F7A069']
export const finalScoreGradientColors = ['#C17CBE', '#5E8DDD']
export const errorRed = '#F84B77'
export const primaryOrange = '#F7A069'
export const primaryRed = '#F84B77'
export const mainPurple = '#C17CBE'
export const playerColor = '#F84B77'
export const uploaderPlayerColor = '#AAADC0'
export const placeholderTextColor = '#00000044'

export {default as DragDropList} from './ui/DragDropList'
export {default as AsyncButtonPresenter} from './ui/AsyncButtonPresenter'
export {default as TestView} from './ui/TestView'
export {default as InMemorySharedStore} from './ui/InMemorySharedStore'
export {default as ShowInstructionsAction} from './instructions/ShowInstructionsAction'
export {default as InMemoryInstructionsStore} from './instructions/InMemoryInstructionsStore'
export {default as InstructionsPresenter} from './instructions/InstructionsPresenter'
export {default as URL} from './util/URL'
export {default as FetchRequest} from './network/FetchRequest'
export {default as PutRequestAction} from './network/PutRequestAction'
export {default as PostRequestAction} from './network/PostRequestAction'
export {default as DeleteRequestAction} from './network/DeleteRequestAction'
export {default as GetRequestAction} from './network/GetRequestAction'
export {default as ReplaceUserAction} from './user/ReplaceUserAction'
export {default as EraseUserAction} from './user/EraseUserAction'
export {default as GetUserAction} from './user/GetUserAction'
export {default as LoginUserAction} from './user/LoginUserAction'
export {default as InMemoryUserStore} from './user/InMemoryUserStore'
export {default as GetUserInteractor} from './user/GetUserInteractor'
export {default as CreateUserAction} from './user/CreateUserAction'
export {default as APIGame} from './game/APIGame'
export {default as Game} from './game/Game'
export {default as InMemoryGameStore} from './game/InMemoryGameStore'
export {default as GameForPresenter} from './game/GameForPresenter'
export {default as ScorePresenter} from './game/score/ScorePresenter'
export {default as NewGameAction} from './game/newGame/NewGameAction'
export {default as OfflineGameLoaderPresenter} from './game/loader/OfflineGameLoaderPresenter'
export {default as transformURL} from './game/loader/transformURL'
export {default as ConcurrentProgressPresenter} from './game/loader/ConcurrentProgressPresenter'
export {default as SingleProgressPresenter} from './game/loader/SingleProgressPresenter'
export {default as VideoDownloaderCoordinator} from './game/loader/VideoDownloaderCoordinator'
export {default as VideoDownloaderWatcher} from './game/loader/VideoDownloaderWatcher'
export {default as GameLoaderPresenter} from './game/loader/GameLoaderPresenter'
export {default as GameFinalScorePresenter} from './game/finalScore/GameFinalScorePresenter'
export {default as ValueStore} from './game/ValueStore'
export {default as GameCardPresenter} from './game/GameCardPresenter'
export {default as GameRouter} from './game/router/GameRouter'
export {default as GameEventHandler} from './game/router/GameEventHandler'
export {default as StatusPresenter} from './status/StatusPresenter'
export {default as WelcomePresenter} from './welcome/WelcomePresenter'
export {default as AuthorizeRequest} from './links/AuthorizeRequest'
export {default as FunctionAction} from './links/FunctionAction'
export {default as ActionQueue} from './links/ActionQueue'
export {default as addAPIKey} from './links/addAPIKey'
export {default as AddJSONHeaders} from './links/AddJSONHeaders'
export {default as LinksToActions} from './links/LinksToActions'
export {default as ObservableAction} from './links/ObservableAction'
export {default as TestAction} from './links/TestAction'
export {default as PrependBaseURL} from './links/PrependBaseURL'
export {default as RequestAction} from './links/RequestAction'
export {default as InMemoryStore} from './data/InMemoryStore'
export {default as GetErrorMessage} from './error/GetErrorMessage'
export {default as AddQuestionPresenter} from './questions/addQuestion/AddQuestionPresenter'
export {default as BatchEditQuestionAction} from './questions/updateQuestion/BatchEditQuestionAction'
export {default as QuestionsPresenter} from './questions/QuestionsPresenter'
export {default as SuggestionsPresenter} from './suggestions/SuggestionsPresenter'
export {default as PhotoUploadPresenter} from './photoUpload/PhotoUploadPresenter'
export {default as PhotoUploadAction} from './photoUpload/PhotoUploadAction'
export {default as SetPlayersAction} from './game/SetPlayersAction'
