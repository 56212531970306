import React from "react"

export default function ReplayVideo() {
  return (
    <svg width="100%" height="100%" viewBox="0 0 50 50" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
      <title>replay</title>
      <desc>Created with Sketch.</desc>
      <g id="Redesign-NEW-colours" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Yes-or-no-Copy-2" transform="translate(-23.000000, -257.000000)" fill="#FFFFFF" fillRule="nonzero">
          <g id="replay" transform="translate(25.000000, 259.000000)">
            <g id="Upload-Copy" fillOpacity="0.8">
              <path d="M23,47.4935065 C9.47260989,47.4935065 -1.49350649,36.5273901 -1.49350649,23 C-1.49350649,9.47260989 9.47260989,-1.49350649 23,-1.49350649 C36.5273901,-1.49350649 47.4935065,9.47260989 47.4935065,23 C47.4935065,36.5273901 36.5273901,47.4935065 23,47.4935065 Z M23,44.5064935 C34.8777084,44.5064935 44.5064935,34.8777084 44.5064935,23 C44.5064935,11.1222916 34.8777084,1.49350649 23,1.49350649 C11.1222916,1.49350649 1.49350649,11.1222916 1.49350649,23 C1.49350649,34.8777084 11.1222916,44.5064935 23,44.5064935 Z" id="Oval-2-Copy-5"></path>
            </g>
            <g id="Group-4" transform="translate(22.969770, 22.234838) rotate(206.000000) translate(-22.969770, -22.234838) translate(10.723016, 9.689384)" fillOpacity="0.845986187">
              <g id="Group-5" transform="translate(0.000000, 0.000000)">
                <path d="M5.63839312,18.6906237 C7.22764572,20.530013 9.43234906,21.436115 12.370906,21.436115 C17.3641172,21.436115 21.4126068,17.3831271 21.4126068,12.3826601 C21.4126068,7.38219321 17.3641172,3.32920523 12.370906,3.32920523 C7.57451849,3.32920523 3.32920523,7.73272685 3.32920523,12.3826601 L0.255210351,12.3826601 C0.255210351,6.05966313 5.85112254,0.255210351 12.370906,0.255210351 C19.0626023,0.255210351 24.4866017,5.68523642 24.4866017,12.3826601 C24.4866017,19.0800839 19.0626023,24.5101099 12.370906,24.5101099 C8.91528641,24.5101099 6.07948632,23.4605586 3.95215707,21.3818101 L4.25668124,23.1088525 L1.9245542,23.5200695 L0.799439369,17.1392262 L6.56167232,16.123189 L6.97288924,18.4553161 L5.63839312,18.6906237 Z" id="Combined-Shape"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
