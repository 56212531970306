export default function AddJSONHeaders(data) {
  addData(data)
  addHeaders(data)
  addJSONHeaders(data)
  return data
}

function addData(data) {
  if (data.data == null) {
    data.data = {}
  }
}

function addHeaders(data) {
  if (data.data.headers == null) {
    data.data.headers = {}
  }
}

function addJSONHeaders(data) {
  if (data.data.headers['Content-Type'] == null) {
    data.data.headers['Content-Type'] = 'application/json'
  }
  data.data.headers.Accept = 'application/json'
}
