import LinksToActions from './LinksToActions'

export function assertCreatesRequest(request, payload, buildAction) {
  let invokedData = null
  const requestFactorySpy = (data) => {
    invokedData = data
    return {
      execute: () => {},
      createRequest: () => {},
    }
  }
  const action = buildAction(requestFactorySpy)
  const transformer = new LinksToActions()
  transformer.addAction('test_link', action)
  const actions = transformer.transform({test_link: {href: testHref}})
  actions.test_link(payload)
  expect(invokedData).toEqual(request)
}

export function executeAction(action) {
  const transformer = new LinksToActions()
  transformer.addAction('test_link', action)
  const actions = transformer.transform({test_link: {href: testHref}})
  actions.test_link()
}

export const testHref = '/test_url'
export const testFullHref = `http://example.com${testHref}`
