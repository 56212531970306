import React from "react"

export default function StarBadge() {
  return (
    <svg width="100%" height="100%" viewBox="0 1 86 45" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
      <title>starBadge</title>
      <desc>Created with Sketch.</desc>
      <g id="Redesign-NEW-colours" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Game_final_page-Copy-8" transform="translate(-241.000000, -61.000000)">
          <g id="Group-2" transform="translate(-37.000000, -46.000000)">
            <g id="starBadge" transform="translate(278.000000, 107.000000)">
              <path d="M0,0 L86,0 L86,25.9859155 L86,38 C86,41.8659932 82.8659932,45 79,45 L7,45 C3.13400675,45 4.73447626e-16,41.8659932 0,38 L0,0 Z" id="Rectangle-28" fill="#18406E"></path>
              <path d="M40.1327081,13.3963993 L42.5,9 L44.8672919,13.3963993 C45.5649439,14.6920387 46.9176465,15.5 48.3891763,15.5 L56.5,15.5 L51.674302,19.6363126 C50.3741897,20.7506945 49.9276103,22.5690256 50.5635607,24.1589017 L53.5,31.5 L44.5349172,26.2024511 C43.27969,25.4607259 41.72031,25.4607259 40.4650828,26.2024511 L31.5,31.5 L34.771441,24.2301312 C35.4997757,22.6116096 35.0744851,20.7067016 33.7269204,19.5516461 L29,15.5 L36.6108237,15.5 C38.0823535,15.5 39.4350561,14.6920387 40.1327081,13.3963993 Z" id="Path-46" fill="#F8E71C"></path>
              <path d="M68.8601008,26.7765436 L70.3454545,24 L71.8308083,26.7765436 C72.2483903,27.5571217 73.0617646,28.0444444 73.9470199,28.0444444 L79,28.0444444 L75.966209,30.6618328 C75.1919468,31.3298237 74.9259805,32.415049 75.3036327,33.3653503 L77.1454545,38 L71.5723047,34.6852507 C70.816218,34.2355521 69.8746911,34.2355521 69.1186044,34.6852507 L63.5454545,38 L65.5984351,33.4080028 C66.031023,32.4404134 65.7776948,31.3036321 64.9751939,30.6112784 L62,28.0444444 L66.7438892,28.0444444 C67.6291445,28.0444444 68.4425188,27.5571217 68.8601008,26.7765436 Z" id="Path-46" fill="#F8E71C"></path>
              <path d="M13.8601008,26.7765436 L15.3454545,24 L16.8308083,26.7765436 C17.2483903,27.5571217 18.0617646,28.0444444 18.9470199,28.0444444 L24,28.0444444 L20.966209,30.6618328 C20.1919468,31.3298237 19.9259805,32.415049 20.3036327,33.3653503 L22.1454545,38 L16.5723047,34.6852507 C15.816218,34.2355521 14.8746911,34.2355521 14.1186044,34.6852507 L8.54545455,38 L10.5984351,33.4080028 C11.031023,32.4404134 10.7776948,31.3036321 9.97519395,30.6112784 L7,28.0444444 L11.7438892,28.0444444 C12.6291445,28.0444444 13.4425188,27.5571217 13.8601008,26.7765436 Z" id="Path-46" fill="#F8E71C"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
