import ReactGA from 'react-ga';
import { analyticsID, useAnalytics } from "../config"

export default class GoogleAnalytics {

  constructor() {
    if (useAnalytics) {
      ReactGA.initialize(analyticsID);
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }

  static _instance = new GoogleAnalytics()

  static instance() {
    return this._instance
  }

  logScreenView = (screenName) => {
    if (useAnalytics) {
      ReactGA.pageview(screenName)
    }
  }

  logEvent = (category, action) => {
    if (useAnalytics) {
      ReactGA.event({
        category: category,
        action: action,
      })
    }
  }
}