import { baseURL, apiKey } from "./config"
import React, { Component } from 'react';
import './App.css';
import 'normalize.css'
import { fetchDependencies } from '@mrmrs/core'
import GameScreen from "./game/GameScreen";
import getURLParameters from "./game/parameters/getURLParameters";
import LandscapeView from "./ui/LandscapeView";

fetchDependencies.baseURL = baseURL
fetchDependencies.apiKey = apiKey
fetchDependencies.useCors = true

class App extends Component {

  constructor(props) {
    super(props)
    this.errorMessage = "temp error"
    try {
      this.params = getURLParameters(this.getWindowURL())
    } catch (error) {
      this.errorMessage = error
    }
  }

  getWindowURL() {
    if (this.props.getWindowURL) {
      return this.props.getWindowURL()
    } else {
      return window.location.href
    }
  }

  componentDidMount() {
    window.onbeforeunload = function () {
      return "Are you sure you want to leave the current game?"
    }
  }

  render() {
    return (
      <div className="App">
        {this.renderGame()}
      </div>
    );
  }

  renderGame() {
    if (this.params) {
      return (
        <LandscapeView minWidth={568} window={window}>
          <GameScreen token={this.params.token} href={{ href: this.params.path }} />
        </LandscapeView>
      )
    } else {
      return <div className="app__error">{this.errorMessage}</div>
    }
  }
}

export default App;
