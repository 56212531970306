import React from "react"

export default function Spinner() {
  return (
    <svg width="48px" height="48px" viewBox="0 0 48 48" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
      <title>Group Copy 3</title>
      <desc>Created with Sketch.</desc>
      <defs>
        <linearGradient x1="0%" y1="15.114813%" x2="37.5776706%" y2="78.2736339%" id="linearGradient-1">
          <stop stopColor="#FFFFFF" stopOpacity="0" offset="0%"></stop>
          <stop stopColor="#FFFFFF" offset="100%"></stop>
        </linearGradient>
      </defs>
      <g id="Redesign-NEW-colours" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="iphone8_Welcomescreen-Copy-10" transform="translate(-199.000000, -702.000000)" fillRule="nonzero" strokeWidth="4">
          <g id="Group-Copy-3" transform="translate(223.110167, 726.110167) scale(-1, 1) translate(-223.110167, -726.110167) translate(193.110167, 696.110167)">
            <circle id="Oval" strokeOpacity="0.212182971" stroke="#FFFFFF" transform="translate(30.000000, 30.000000) rotate(-128.000000) translate(-30.000000, -30.000000) " cx="30" cy="30" r="21.36"></circle>
            <path d="M8.64,30 C8.64,41.7968023 18.2031977,51.36 30,51.36" id="Path" stroke="url(#linearGradient-1)"></path>
          </g>
        </g>
      </g>
    </svg>
  )
}
