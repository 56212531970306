import APIGame from '../APIGame'
import Game from '../Game'
import GetErrorMessage from '../../error/GetErrorMessage'

export default class GameLoaderPresenter {
  constructor(config) {
    this.view = config.view
    this.downloader = config.downloader
    this.progressPresenter = config.progressPresenter
    this.fetchGame = config.fetchGame
    this.router = config.router
    this.store = config.store
  }

  load() {
    this.view.setProgress(0)
    this.view.setStartGame(null)
    this.view.setIsDownloadingVideo(false)
    this.view.setIsInProgress(true)
    this.view.setDownloadMessage('Downloading your game')
    this.fetchGame()
      .then((g) => {
        const game = new APIGame(g)
        this.view.setIsDownloadingVideo(true)
        this.downloader.startDownloading(game.getURLs(), this)
        this.game = game
      })
      .catch((error) => this.didError(error))
  }

  reload() {
    this.view.setDownloadFailedMessage(null)
    this.load()
  }

  unload() {
    this.downloader.cancel()
  }

  didComplete(downloadedURLs) {
    this.downloadedURLs = downloadedURLs
    this.view.setIsInProgress(false)
    this.view.setStartGame(this.startGame)
    const game = this.createGame(this.game, this.downloadedURLs)
    this.store.create(game.toJSON())
  }

  didProgress(progress) {
    this.progressPresenter(this.view, progress)
  }

  didError(error) {
    this.view.setIsInProgress(false)
    this.view.setDownloadFailedMessage(GetErrorMessage(error))
  }

  startGame = () =>
    this.router.didCompleteLoadingGame(
      this.createGame(this.game, this.downloadedURLs),
    )

  createGame(apiGame, downloadedURLs) {
    const items = apiGame.getQuestions().map((q) => {
      return {
        question: q.getText(),
        videoURL: downloadedURLs[q.getAnswer().getURL()],
      }
    })
    const subject = {
      name: apiGame.getSubject().name,
      initial: apiGame.getSubject().initial,
      image: downloadedURLs[apiGame.getSubject().image],
    }
    const uploader = {
      name: apiGame.getUploader().name,
      initial: apiGame.getUploader().initial,
      image: downloadedURLs[apiGame.getUploader().image],
    }
    const sounds = {}
    Object.keys(apiGame.getSounds()).forEach((s) => {
      sounds[s] = downloadedURLs[apiGame.getSounds()[s]]
    })
    return new Game(items, subject, uploader, sounds)
  }
}
